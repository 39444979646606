<template>
  <div id="app">

<Menu></Menu>
<!-- -->
    <router-view/>

    <!-- -->
   <cookie-law buttonText="Ok" >
  <div slot="message">
   Usamos cookies para garantir que você obtenha a melhor experiência de navegação em nosso site. Não compartilhamos as informações com terceiros.  <router-link to="politica-de-privacidade">Veja mais</router-link>
  </div>
</cookie-law>

    <footer class="container-fluid">
      <div class="row">
        <div class="col-sm-3">
          <img id="logo-footer" style="max-height:120px;width:auto;"  src="/img/logo_fundo_branca_footer.png" class="img-fluid mx-auto d-block" alt="Logo Tarimba rodape" />
        </div>

        <div class="col-sm-4"><br>
          <h6 style="font-weight:bold;">©Copyright 2019 - Tarimba na Cozinha <br> TODOS OS DIREITOS RESERVADOS</h6><br>
          <p style="font-weight:bold;">contato@tarimbanacozinha.com.br</p>
          <p><router-link style="font-size:0.8em !important;color:#FFF;"  to="/termos-de-uso" >TERMOS DE USO | </router-link>
          <router-link style="font-size:0.8em !important;color:#FFF;" to="/politica-de-privacidade" > POLÍTICAS DE PRIVACIDADE</router-link></p>
        </div>

         <div class="col-sm-2">
           <p class="text-white  mt-3">Apoiadores:</p>
   <a target="_blank" href="https://idec.org.br/"><img id=""   src="/img/logo-idec-footer.png" class="img-fluid  mt-1 logos-rodape fix-idec"  alt="Logo IDEC rodape " style="display:inline-block;max-height:30px;"/></a>
           <p class="text-white  mt-3">Empresas parceiras:</p>
           <div class="row">
              <a class="col-sm-6" target="_blank" href="https://organicosinbox.com.br/"><img id=""   src="/img/logo_organicos_footer.png" class="img-fluid  mb-3 logos-rodape"  alt="Logo Organicos rodape" style="display:inline-block;" /></a>
            <a class="col-sm-6" target="_blank" href="https://superopa.com/"><img id=""   src="/img/superopa.png" class="img-fluid  mb-3 logos-rodape"  alt="Logo Superopa rodape " style="display:inline-block;"/></a>
          
         <a class="col-sm-6" target="_blank" href="https://euorganico.eco.br/"><img id=""   src="/img/logo_euorganico_branco.png" class="img-fluid  mb-3 logos-rodape"  alt="Logo" style="display:inline-block;" /></a>
          <a class="col-sm-6"   target="_blank" href="https://www.minhaquitandinha.com.br/"><img id=""   src="/img/logo_quitandinha_branco.png" class="img-fluid  mb-3 logos-rodape"  alt="Logo" style="display:inline-block;" /></a>
            <a class="col-sm-6"  target="_blank" href="https://br.sodexo.com"><img id=""   src="/img/logo_sodexo_footer.png" style="display:inline-block;" class="ml-sm-3 img-fluid logos-rodape fix-sodexo"  alt="Logo Sodexo rodape" /></a>
             
           </div>
        
     
         
        </div>

        <div class="col-sm-3"><br>
          <h5 class="center-mobile" style="font-weight:bold;">Siga a Tarimba na Cozinha</h5><br>
          <div id="redes-rodape" style="margin:0 auto;width:100% ;">
            <a target="_blank" href="https://www.instagram.com/tarimbanacozinha/"><img style="display:inline-block;max-height:30px;" src="/img/instagram.png" class="img-fluid" alt="Instagram Tarimba" /></a>
             <a target="_blank" href="https://www.facebook.com/tarimbanacozinha"><img style="display:inline-block;max-height:30px;" src="/img/facebook.png" class="img-fluid" alt="Facebook Tarimba" /></a>
              <a target="_blank" href="https://www.youtube.com/channel/UC1WqNWykk0GJZ1dVKmdRV2Q"><img style="display:inline-block;max-height:30px;" src="/img/youtube.png" class="img-fluid" alt="Youtube Tarimba" /></a>
          </div>
          
        </div>

        </div>
    </footer>

  </div>
</template>

<style>
.Cookie__button{background-color:#440D45 !important;}

@font-face {
font-family: plume;
src: url(/fonts/Plume_Std_Rg.b6cec4f6b3413339518e.ttf);
}

@font-face {
font-family: plumeBold;
src: url(/fonts/Plume_Std_Bd.aa1dc7e37f18c49e4e77.ttf);
}

*{font-family: plume;-webkit-font-smoothing: antialiased;}
pre { 
    white-space: pre-wrap !important; 
    word-break: break-word !important;
}
option{color:#000 !important;}
.bold{font-family: plumeBold;}
.menu-tarimba{background-color:#440D45 !important;}
.menu-tarimba  .nav-item > a{color:#FFF !important;font-size:1.0em;font-weight:500;margin-right:20px;}
.menu-tarimba  .nav-item > a:hover{color:#F18800 !important;}
.logo-menu{height:40px !important;width:auto !important;margin:15px 0;}

footer{background-color:#F18800 ; color:#FFF;padding:30px 0;}
#redes-rodape img{padding:0 20px;}
.menu-login{min-width:250px !important;padding:25px !important;line-height: 1.0 !important;}
#form-login label{font-size:0.8em !important;color:#000;}
#form-login input{font-size:0.8em !important;color:#000;border-color:#440D45 !important;}

@media all and (max-width:1279px){
  .logo-menu{height:40px !important;}
  .menu-tarimba  .nav-item > a{margin-right:10px;}
  .menu-tarimba  .nav-item > a{font-size:0.8em;}
  footer p,footer h6,footer h3{text-align:center !important;}
  #redes-rodape{width:140px !important}
  #redes-rodape img{height:30px !important;;width:auto }
  #logo-footer{height:80px !important;}
}

@media all and (max-width:768px){
  .bt-mobile-full{float:none !important;width:100% !important;}
  .center-mobile{text-align:center;}
  #redes-rodape{width:70% !important;}
  .logos-rodape{display:block !important;margin:0 auto;}
  .fix-sodexo{margin-top:-20px !important;margin-bottom:25px !important;}
}

@media all and (min-width:768px){
  #redes-rodape{width:100% !important;}
  .fix-idec{margin-left:20px;}
}
</style>

<script>
import Menu from '@/components/Menu'
import CookieLaw from 'vue-cookie-law'

export default {
  name: 'App',
  components: {Menu,CookieLaw},
   metaInfo: {
   
    
    meta: [
     
      { property: 'og:image', content: 'https://tarimbanacozinha.com.br/img/logo_tarimba.jpg' },
      { property: 'og:url', content: 'https://tarimbanacozinha.com.br/login' },
         { property: 'og:type', content: 'website' },
            { property: 'og:image:type', content: 'image/jpeg' },
       { property: 'og:title', content: 'Tarimba na cozinha - Receitas' },
       { property: 'og:description', content: 'Encontre receitas com os ingredientes que você tem na sua geladeira e na sua despensa.' }
 
    ]
  },
  data(){
    return {
      
    }
  },
  methods:{
   
  }
}
</script>