<template>
 
<nav class="navbar navbar-expand-lg navbar-light  menu-tarimba">
   <router-link to="/"><img  src="/img/logo_fonte_branca.png" class="img-fluid logo-menu" alt="Logo Tarimba" /></router-link>
  <button style="background-color:#F18800 !important;outline:none !important;" class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span  class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse " id="navbarSupportedContent">
    <ul class="navbar-nav ml-auto ">
      <li class="nav-item active">
        
         <router-link to="/" v-if="logado == null" class="nav-link ">A TARIMBA</router-link>
         
      </li>
     
        <li class="nav-item"> <router-link  v-if="logado == null" v-scroll-to="'#parcerias'" to="/#parcerias" class="nav-link">PARCERIAS TARIMBA</router-link></li>
         <li class="nav-item"> <router-link  to="/receitas" class="nav-link">ENCONTRE RECEITAS</router-link></li>
         <li class="nav-item"> <router-link v-if="logado"  to="/compartilhe" class="nav-link">COMPARTILHE SUA RECEITA</router-link></li>
         <li class="nav-item"> <router-link v-if="logado"  to="/livro-de-receitas" class="nav-link">SEU LIVRO DE RECEITAS</router-link></li>
         <li class="nav-item"> <router-link v-if="logado"  to="/receitas-para-testar" class="nav-link">RECEITAS PARA TESTAR</router-link></li>
         <li class="nav-item"> <router-link v-if="logado"  to="/lista-de-compras" class="nav-link">LISTA DE COMPRAS</router-link></li>
         <li class="nav-item"> <router-link to="/blog" class="nav-link">BLOG</router-link></li>
          <li class="nav-item"><router-link  v-if="logado == null" to="/cadastro" class="nav-link">CADASTRE-SE</router-link></li>
       
      
     
      <li   v-if="logado == null" class="nav-item dropdown">
        <a  class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          ENTRE
        </a>
        <div ref="boxLogin" style="background:#F9F7EA;" class="dropdown-menu menu-login dropdown-menu-right" aria-labelledby="navbarDropdown">
         
                <p class="" style="color:#F18800;"><strong>Você já se cadastrou?</strong><br><br>
<small>Sim. Coloque abaixo seus dados de acesso e clique no botão "Entrar".</small></p>

                <form id="form-login" name="login" >
                  <div class="form-group">
                    <label>E-mail</label>
                    <input type="text" class="form-control" v-model="email" name="email" placeholder="E-mail" aria-describedby="email" required>
                    <small class="form-text text-muted"></small>
                  </div>
                  <div class="form-group">
                     <label>Senha</label>
                     <div class="input-group ">
                     

                    <input  v-if="showPassword" type="text" class="form-control" v-model="senha" name="senha" placeholder="Senha" aria-describedby="senha" required>
                    <input v-else type="password" class="form-control" v-model="senha" name="senha" placeholder="Senha" aria-describedby="senha" required>
                    <div class="input-group-append" >
                      <div @click="toggleShow" class="input-group-text" style="cursor:pointer;"><img  src="/img/olho.png" class="img-fluid" alt="Senha" style="width:20px;height:20px;" /></div>
                    </div>
                     </div>
                  </div>

                  <small><i><router-link to="/resgate" style="font-size:1.1em;" class="dropdown-item text-center">Esqueceu sua senha?</router-link></i></small>
                  <br>
                  
                 <button v-if="showLoading == false" style="width:100%;" @click.prevent="logar" type="button " class="btn bt-roxo btn-primary bold">ENTRAR</button>
                 <small class="text-center d-block" v-if="erro != ''" style="color:red;margin-top:10px;">{{erro}}</small>
                </form>
                
                  <div v-if="showLoading" class="d-flex justify-content-center">
                    <div class="spinner-border" role="status">
                      <span class="sr-only">Loading...</span>
                    </div>
                  </div>
<br><br>
                <small style="color:#440D45;">Não. Então  <router-link class="bold" style="color:#440D45;" to="/cadastro">CLIQUE AQUI</router-link> para conhecer como o Tarimba na Cozinha pode te ajudar no dia a dia.</small>

        </div>
      </li>


      <!-- -->
<div v-if="logado" class="dropdown">
  <button class="btn btn-secondary dropdown-toggle bold" style="background-color:#F18800 !important;color:#FFF !important;" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
     {{firstName}}
  </button>

  

  <div style="background:#F9F7EA;" class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
    <router-link to="/perfil" class="dropdown-item text-center">Meu Perfil</router-link>
    <!-- <router-link to="/transacoes" class="dropdown-item text-center">Pagamentos</router-link> -->
    <!-- <router-link to="/cadastro" class="dropdown-item text-center">Estender Assinatura</router-link> -->
     
<hr>
<li style="cursor:pointer;" @click="logout" class="dropdown-item text-center">Sair</li>
    <!-- <small v-if="ativo == false" class="text-center d-block" style="color:red;">Sua assinatura expirou!</small>
     <small class="text-center d-block">{{tipoConta }}</small>
     <small class="text-center d-block"><i>Expira em: {{expiracao }}</i></small> -->
  </div>
</div>

      <!-- -->
     
    </ul>

  </div>
</nav>





</template>

<script>
import servidor from '@/servidor' 

export default {
  name: 'Menu',
  components: {},
  data(){
    return {
      showPassword: false,
      showLoading:false,
      email:'',
      senha:'',
      erro:''
    }
  },
  methods:{
     toggleShow() {
      this.showPassword = !this.showPassword;
    },
    logar(){
      this.showLoading = true;
      this.erro = 'Aguarde...';
      let dados = new FormData();
      
      dados.append('email',this.email);
      dados.append('senha',this.senha);

      fetch(servidor+'verificacao/logar',{method:"POST", body:dados})
          .then((response) => response.json())
          .then((data) => {
            this.showLoading = false;
            console.log(data);

            if(data.erro != null){
                this.erro = data.erro;
            }else{
              this.$store.commit('logarUsuario',data);
              this.setCookie('usuario',JSON.stringify(this.logado), this.logado.dias);
              this.$router.push('/receitas');
            }
          });
    },
    logout(){
      this.$store.commit('logarUsuario',null);
      this.deleteCookie();
      this.$router.push('/');
    },
    setCookie(cname, cvalue, exdays) {
      var d = new Date();
      d.setTime(d.getTime() + (exdays*24*60*60*1000));
      var expires = "expires="+ d.toUTCString();
      document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    },
    deleteCookie(){
      document.cookie = "usuario=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    },
  },
  computed:{
    logado(){
      return this.$store.state.usuario;
    },
    firstName(){
      let nome =  this.$store.state.usuario.nome.split(' ');
      return nome[0].toUpperCase();
    },
     tipoConta(){
      let conta =  this.$store.state.usuario.permissao;
      return conta;
    },
    ativo(){
      let ativo =  this.$store.state.usuario.ativo;
      return ativo;
    },
     expiracao(){
      let expiracao =  this.$store.state.usuario.expiracao;
      switch(this.tipoConta){
        case "Geral":
          expiracao = "Permanente"
          break;

          case "Personalizado":
          expiracao = "Permanente"
          break;

          case "Permanente":
          expiracao = "Permanente"
          break;

      }
      return expiracao;
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>


</style>
